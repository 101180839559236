// components/Experiences.js
import React from "react";
import { useTheme } from "../ThemeContext";

const experiences = [
  {
    role: "Software Developer at TT-Technologies",
    period: "01/09/2021 - Present",
    location: "Tetouan",
    description: `Collaborated with a remote team based in the Netherlands as a Mobile Developer, focusing on a React Native application. Employing Agile methodologies, played a key role in developing new features, maintaining the application, upgrading legacy libraries, and conducting unit testing using Jest library.`,
  },
  {
    role: "Full Stack Developer at Youpel",
    period: "01/12/2019 - 03/2021",
    location: "Tangier",
    description: `Engaged in a wide range of tasks encompassing front end, backend, database administration, and cloud management. Led the creation of applications from inception using React.js and Express.js with MongoDB, developed bots and scripts with Node.js, managed Linux servers, installed necessary plugins and libraries to run applications on the cloud, and performed troubleshooting and maintenance on clients' WordPress websites.`,
  },
  {
    role: "Web Developer at STG Maroc",
    period: "01/04/2021 - 01/08/2021",
    location: "Tangier",
    description: `Served as a Front End Angular Developer, collaborating with a dedicated team to introduce new features and implement user interfaces.`,
  },
  {
    role: "Freelance Software Developer",
    period: "Since 2021",
    location: "Remote",
    description: `Developed a variety of applications and scripts independently. Created a course platform called Biliviance, migrated video files from Vimeo to Amazon S3, developed a React Native application for an association in Casablanca, collaborated on a Flutter-based application for COVID-19, provided fixes for an ERP script, and developed scripts for WebIO.`,
  },
  // Add more experiences here
];

function Experiences() {
  const { theme } = useTheme();
  return (
    <div className={`${theme==='dark' ?"bg-gray-800 text-white":"bg-white-800"} mx-auto py-20 px-10`}>
      <h2 className="text-4xl font-bold mb-8 text-center">My Experiences</h2>
      <div className="space-y-8">
        {experiences.map((experience, index) => (
          <div key={index} className="bg-red-900 text-white p-6 rounded-lg">
            <h3 className="text-2xl font-bold mb-2">{experience.role}</h3>
            <p className="text-sm mb-2">
              {experience.period} | {experience.location}
            </p>
            <p>{experience.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Experiences;
