// components/Home.js
import React from "react";
import heroImage from "../assets/heroImage.jpg";
import SocialLinks from "./SocialLinks";
import { useTheme } from "../ThemeContext";

function Home() {
  const { theme } = useTheme();

  return (
    <div
      className={`relative text-center py-10 md:py-20 overflow-hidden h-full ${
        theme === "dark"
          ? "animated-background"
          : "animated-background-white"
      }`}
    >
      <div className="circle circle-1"></div>
      <div className="circle circle-2"></div>
      <div className="circle circle-3"></div>
      <img
        src={heroImage}
        alt="Aziz Mobarak"
        className="w-32 h-32 md:w-40 md:h-40 rounded-full mx-auto mb-4"
      />
      <h1 className="text-3xl md:text-5xl text-white font-bold mb-4">
        Aziz Mobarak
      </h1>
      <p className="text-base md:text-lg text-white mb-8">
        Web & Mobile Software Developer
      </p>
      <a
        href="/projects"
        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm md:text-base"
      >
        View My Work
      </a>
      <SocialLinks />
    </div>
  );
}

export default Home;
