// components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../ThemeContext";

function Navbar() {
  const { theme, toggleTheme } = useTheme();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav
      className={`p-4 ${
        theme === "dark"
          ? "bg-gray-800 text-white"
          : "bg-gray-100 text-gray-900"
      }`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-sm lg:text-2xl font-bold">
          Aziz Mobarak
        </Link>
        <button
          className="block lg:hidden p-2 text-3xl"
          onClick={handleMobileMenuToggle}
        >
          {isMobileMenuOpen ? "✖️" : "☰"}
        </button>
        <div
          className={`${
            isMobileMenuOpen
              ? "block flex flex-col absolute right-0 mt-80 z-40 items-start w-1/2 bg-gray-600 gap-2 p-4 border-bottom-2"
              : "hidden flex items-center lg:flex space-x-4 "
          }`}
        >
          <Link to="/" className="hover:text-gray-400">
            Home
          </Link>
          <Link to="/projects" className="hover:text-gray-400">
            Projects
          </Link>
          <Link to="/skills" className="hover:text-gray-400">
            Skills
          </Link>
          <Link to="/experiences" className="hover:text-gray-400">
            Experiences
          </Link>
          <Link to="/contact" className="hover:text-gray-400">
            Contact
          </Link>
          <button
            onClick={toggleTheme}
            className="ml-4 p-2 text-3xl rounded-full focus:outline-none"
          >
            {theme === "dark" ? "🌞" : "🌜"}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
