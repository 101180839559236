// components/Footer.js
import React from "react";
import { useTheme } from "../ThemeContext";

function Footer() {
  const { theme } = useTheme();
  return (
    <footer
      className={`p-4 text-center ${
        theme === "dark"
          ? "bg-gray-800 text-white"
          : "bg-gray-100 text-gray-900"
      }`}
    >
      <p>&copy; 2024 Aziz Mobarak. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
