// components/Projects.js
import React from "react";
import { useTheme } from "../ThemeContext";


const personalProjects = [
  {
    title: "React-screen-modal",
    description:
      "A React.js library to simplify showing modals and notifications.",
    link: "https://github.com/azizmobarak/react-screen-modal",
  },
  // Add more personal projects here
];

const workProjects = [
  {
    title: "Tawaaiya",
    description:
      "A mobile application developed to address COVID-19 challenges using Flutter.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "YavaJob",
    description:
      "A job search platform, a 70% clone of the Indeed platform, using ReactJs, ExpressJs, Puppeteer, and MySQL.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "The Medical",
    description:
      "A comprehensive platform developed for Sipmover to manage patient records using ReactJs, MySQL, NodeJS, and MongoDB.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "STG telematics",
    description: "A fleet management web application using Angular.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "STG fleet consummation app",
    description:
      "A React Native app to get updated fleet volume, connected to Firebase.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "Carexs health app",
    description:
      "A platform connecting nurses with patients, built on React Native.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "Biliviance",
    description:
      "A course platform using Next.js, Node.js (Express), and MongoDB, along with AWS services.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "React Native application for an association in Casablanca",
    description:
      "A small React Native application developed for an association in Casablanca.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "Flutter-based application for COVID-19",
    description:
      "A Flutter-based application created to address COVID-19 challenges.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "ERP script fixes and scripts development for WebIO",
    description:
      "Provided fixes for an ERP script and developed various scripts for WebIO.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "Teacher Course Upload Platform",
    description:
      "A platform allowing teachers to upload courses using S3, NodeJs API, MongoDB, and NextJs for server-side rendering.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "Woodwing File Management Project",
    description:
      "A file-sharing and management platform using Angular, developed for Woodwing.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "Edutech Video Migration and Mobile Fixes",
    description:
      "Migrated video files to S3, fixed mobile issues, and created a streaming app for students and a React Native app replacing an old Flutter app for Edutech.",
    link: "https://github.com/azizmobarak",
  },
  {
    title: "School Project for TT-technologies",
    description:
      "A school project using Fastify, ReactJs, React Native Expo, and MongoDB for TT-technologies.",
    link: "https://github.com/azizmobarak",
  },
];

function Projects() {
  const {theme} = useTheme();
  return (
    <div
      className={`mx-auto py-20 w-full px-10 ${
        theme === "dark" ? "text-white bg-gray-800" : "bg-white-100 text-dark"
      }`}
    >
      <h2 className="text-4xl font-bold mb-8 text-center">Projects</h2>

      <h3 className="text-3xl font-bold mb-4">Personal Projects</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-8">
        {personalProjects.map((project, index) => (
          <div
            key={index}
            className={`${
              theme === "dark"
                ? "bg-red-900 text-dark-400"
                : "bg-gray-800 text-white"
            } p-6 rounded-lg`}
          >
            <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
            <p className="mb-4">{project.description}</p>
            <a
              href={project.link}
              className="text-blue-400 hover:text-blue-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Project
            </a>
          </div>
        ))}
      </div>

      <h3 className="text-3xl font-bold mb-4">Projects I Worked On</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {workProjects.map((project, index) => (
          <div
            key={index}
            className={`${
              theme === "dark"
                ? "bg-red-900 text-dark-400"
                : "bg-gray-800 text-white"
            } p-6 rounded-lg`}
          >
            <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
            <p className="mb-4">{project.description}</p>
            <a
              href={project.link}
              className="text-blue-400 hover:text-blue-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Project
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
