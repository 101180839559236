// components/Contact.js
import React from "react";
import SocialLinks from "./SocialLinks";

function Contact() {
  return (
    <div className="container mx-auto py-20">
      <h2 className="text-4xl font-bold mb-8 text-center">Contact</h2>
      <div
        className={`flex items-center p-10 flex-col justify-center text-white bg-gray-800`}
      >
        <h3>Contact me on my social media</h3>
        <SocialLinks />
        <br />
        <span>--------------------OR-------------------</span>
        <h4>Contact me via Email :</h4>
        <a className="text-red-400" href="mailto:mobarakaaziz9@gmail.com">
          mobarakaziz9@gmail.com
        </a>
      </div>
    </div>
  );
}

export default Contact;
