// components/Skills.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faAngular,
  faNodeJs,
  faJs,
  faHtml5,
  faCss3Alt,
  faAws,
  faGitAlt,
  faNpm,
  faAndroid,
  faApple
} from "@fortawesome/free-brands-svg-icons";
import {
  faDatabase,
  faCloud,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../ThemeContext";

const skills = [
  { name: "ReactJs/NextJs", icon: faReact },
  { name: "Angular", icon: faAngular },
  { name: "React Native (Expo/CLI)", icon: faReact },
  { name: "NodeJS", icon: faNodeJs },
  { name: "JavaScript/TypeScript", icon: faJs },
  { name: "MongoDB", icon: faDatabase },
  { name: "MySQL/PostgreSQL", icon: faDatabase },
  { name: "Redux/ToolKit", icon: faNpm },
  { name: "HTML/CSS/SASS", icon: faHtml5 },
  { name: "Unit Testing (jest)", icon: faClipboardCheck },
  { name: "Git/Agile", icon: faGitAlt },
  { name: "AWS", icon: faAws },
  { name: "Android", icon: faAndroid },
  { name: "IOS", icon: faApple },
];

function Skills() {
  const {theme} = useTheme();
  return (
    <div
      className={`${
        theme === "dark" ? "bg-gray-800 text-white" : "text-white-800"
      } mx-auto py-20 px-10`}
    >
      <h2 className="text-4xl font-bold mb-8 text-center">Skills</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8">
        {skills.map((skill, index) => (
          <div
            key={index}
            className={`flex flex-col items-center p-4 rounded-lg shadow-md ${
              theme === "dark" ? "bg-red-800" : "bg-gray-800 text-white"
            }`}
          >
            <FontAwesomeIcon icon={skill.icon} className={`text-4xl mb-4`} />
            <h3 className="text-xl font-bold text-center">{skill.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
