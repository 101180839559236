// components/SocialLinks.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

function SocialLinks() {
  return (
    <div className="flex justify-center space-x-6 mt-6">
      <a
        href="https://github.com/azizmobarak"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faGithub}
          className="text-white text-2xl hover:text-gray-400"
        />
      </a>
      <a
        href="https://www.linkedin.com/in/azizmobarak"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faLinkedin}
          className="text-white text-2xl hover:text-gray-400"
        />
      </a>
      <a
        href="https://www.instagram.com/wolfzios"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faInstagram}
          className="text-white text-2xl hover:text-gray-400"
        />
      </a>
      <a
        href="https://twitter.com/mobarakaziz95"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faTwitter}
          className="text-white text-2xl hover:text-gray-400"
        />
      </a>
    </div>
  );
}

export default SocialLinks;
